import React from 'react';
import ChatComponent from './Components/ChatComponent';

function App() {
  return (
      <div>
        <ChatComponent />
      </div>
  );
}

export default App;
