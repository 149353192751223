const API_ENDPOINT = process.env.REACT_APP_BACKBONE_API_ENDPOINT;
const API_KEY = process.env.REACT_APP_BACKBONE_API_KEY;
export const reportBug = async (title, description, messages) => {
    let formattedMessages = {};

    if (messages && messages.messages && Array.isArray(messages.messages)) {
        formattedMessages = messages.messages.reduce((acc, msg, index) => {
            acc[`Message ${index + 1}`] = `${msg.sender}: ${msg.text}\n\n`; // Added two newline characters here
            return acc;
        }, {});
    } else {
        console.warn('Messages object is not in the expected format. Using empty object.');
    }

    const reportData = {
        action: "reportABug",
        system: "maiachatbot",
        title: title || "Inaccurate Information Report",
        description: description || "User reported inaccurate information",
        metadata: formattedMessages
    };

    try {
        const response = await fetch(API_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY
            },
            body: JSON.stringify(reportData)
        });

        if (!response.ok) {
            throw new Error('Failed to submit report');
        }

        return await response.json();
    } catch (error) {
        console.error('Error submitting report:', error);
        throw error;
    }
};