import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Image, Button, Box } from '@chakra-ui/react';
import maiaImageSrc from '../images/MAIA.png';

const ChatHeader = ({ remainingMessages, onNewChat, messageCount }) => {
    const isLowMessages = remainingMessages <= 10;
    const showRemainingMessages = remainingMessages < 5;

    return (
        <Flex
            width="100%"
            bg="gray.800"
            p={4}
            borderRadius="md"
            boxShadow="md"
            mb={3}
            alignItems="center"
            justifyContent={{ base: 'center', md: 'space-between' }}
            flexDirection={{ base: 'column', md: 'row' }}
            position="relative"
        >
            <Flex alignItems="center" order={{ base: 2, md: 1 }} mb={{ base: 2, md: 0 }}>
                {showRemainingMessages && (
                    <Text
                        fontSize={{ base: 'sm', md: 'md' }}
                        color={isLowMessages ? "red.300" : "gray.400"}
                        mr={{ base: 0, md: 4 }}
                    >
                        {remainingMessages} messages left
                    </Text>
                )}
            </Flex>
            <Flex alignItems="center" justifyContent={{ base: 'center', md: 'center' }} flex="1" order={{ base: 1, md: 2 }}>
                <Image
                    src={maiaImageSrc}
                    alt="MAIA"
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    marginRight="8px"
                />
                <Text fontSize="xl" color="white" textAlign="center">
                    Chat with MAIA
                </Text>
            </Flex>
            {messageCount > 0 && (
                <Box order={{ base: 3, md: 3 }} mt={{ base: 2, md: 0 }} pb={{ base: 4, md: 0 }}>
                    <Button
                        size="sm"
                        colorScheme="blue"
                        onClick={onNewChat}
                    >
                        New Chat
                    </Button>
                </Box>
            )}
        </Flex>
    );
};

ChatHeader.propTypes = {
    remainingMessages: PropTypes.number.isRequired,
    onNewChat: PropTypes.func.isRequired,
    messageCount: PropTypes.number.isRequired,
};

export default React.memo(ChatHeader);