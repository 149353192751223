import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Textarea,
    Text,
    VStack,
} from '@chakra-ui/react';

const ReportModal = ({ isOpen, onClose, onSubmit, isInitialPopup }) => {
    const [formData, setFormData] = useState({ title: '', description: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isInitialPopup) {
            const timer = setTimeout(onClose, 2000);
            return () => clearTimeout(timer);
        }
    }, [isInitialPopup, onClose]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }, []);

    const handleSubmit = useCallback(async () => {
        setIsSubmitting(true);
        try {
            await onSubmit(formData.title, formData.description);
            setFormData({ title: '', description: '' });
            onClose();
        } catch (error) {
            console.error('Error submitting report:', error);
        } finally {
            setIsSubmitting(false);
        }
    }, [formData, onSubmit, onClose]);

    const renderInputFields = () => (
        <>
            <Input
                name="title"
                placeholder="Title (optional)"
                value={formData.title}
                onChange={handleInputChange}
                color="black"
                bg="white"
            />
            <Textarea
                name="description"
                placeholder="Description (optional)"
                value={formData.description}
                onChange={handleInputChange}
                color="black"
                bg="white"
            />
        </>
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="#060a0d" color="white">
                <ModalHeader>Report Issue</ModalHeader>
                {!isInitialPopup && <ModalCloseButton />}
                <ModalBody>
                    <VStack spacing={4}>
                        <Text>
                            I am still new and learning! If I did something wrong, please report it so I can get the right data to improve!
                        </Text>
                        {!isInitialPopup && renderInputFields()}
                    </VStack>
                </ModalBody>
                {!isInitialPopup && (
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={handleSubmit}
                            isLoading={isSubmitting}
                            loadingText="Submitting..."
                        >
                            Submit Report
                        </Button>
                        <Button variant="ghost" onClick={onClose} isDisabled={isSubmitting}>Cancel</Button>
                    </ModalFooter>
                )}
            </ModalContent>
        </Modal>
    );
};

ReportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isInitialPopup: PropTypes.bool.isRequired,
};

export default ReportModal;