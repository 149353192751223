export const THEME_COLORS = {
    background: '#060a0d',
    text: 'white',
    brand: {
        pink: '#d1228a',
        blue: '#3b6def',
        purple: '#8e4dbd',
    },
};

export const FACTS = [
    "Did you know Black Box VR is proven AS EFFECTIVE as normal workouts in 4 UCLA studies?",
    "Black Box VR integrates gaming mechanics to make workouts engaging and fun!",
    "Users can compete in virtual reality environments while getting a full-body workout.",
    "Black Box VR tracks progress and adapts workouts to ensure continued improvement.",
    "It's a perfect blend of fitness and technology, transforming traditional workouts into an immersive experience."
];

export const SENDER_USER = 'user';

export const FACT_ROTATION_INTERVAL = 5000; // 5 seconds

export const TOAST_DURATION = 5000; // 5 seconds