import { useEffect, useRef, useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import { AI_MODEL, GREETING_PROMPT } from "../Utils/AiUtils";

const WEBSOCKET_URL = 'wss://gol163iwfk.execute-api.us-west-2.amazonaws.com/development';
const MESSAGE_DONE = '[DONE]';
const SENDER_SERVER = 'server';
const MAX_RETRIES = 3;
const RETRY_DELAY = 3000; // 3 seconds
const TOAST_DURATION = 9000;

const useWebSocket = (setMessages) => {
  const ws = useRef(null);
  const [typingMessage, setTypingMessage] = useState(null);
  const [isThinking, setIsThinking] = useState(false);
  const [remainingMessages, setRemainingMessages] = useState(50);
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const toast = useToast();
  const currentMessageRef = useRef('');
  const retryCount = useRef(0);
  const reconnectTimeoutRef = useRef(null);
  const initialGreetingSentRef = useRef(false);

  const showToast = useCallback((title, description, status) => {
    toast({
      title,
      description,
      status,
      duration: TOAST_DURATION,
      isClosable: true,
    });
  }, [toast]);

  const processMessage = useCallback((messagePart) => {
    console.log("Received message part: ", messagePart);

    if (messagePart.remainingRequests !== undefined) {
      setRemainingMessages(messagePart.remainingRequests);
    }

    if (messagePart.text === MESSAGE_DONE) {
      if (currentMessageRef.current) {
        const finalMessage = {
          sender: SENDER_SERVER,
          text: currentMessageRef.current,
          id: `server-${Date.now()}`
        };
        setMessages(prevMessages => [...prevMessages, finalMessage]);
        setTypingMessage(null);
      }
      currentMessageRef.current = '';
      setIsThinking(false);
    } else if (messagePart.statusCode === 429) {
      showToast("Error", "Rate limit exceeded. Please try again later.", "error");
      setIsThinking(false);
      setRemainingMessages(0);
    } else if (messagePart.error) {
      showToast("Error", `Error: ${messagePart.error}. Contact support@blackbox-vr.com`, "error");
      setIsThinking(false);
    } else if (messagePart.text) {
      currentMessageRef.current += messagePart.text;
      setTypingMessage({
        sender: SENDER_SERVER,
        text: currentMessageRef.current,
        id: 'typing'
      });
    }
  }, [setMessages, showToast]);

  const sendWebSocketMessage = useCallback((payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(payload));
      setIsThinking(true);
      currentMessageRef.current = '';
      setTypingMessage(null);
    }
  }, []);

  const connectWebSocket = useCallback(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      console.log('WebSocket already connected.');
      return;
    }

    setConnectionStatus('connecting');
    ws.current = new WebSocket(WEBSOCKET_URL);

    ws.current.onopen = () => {
      console.log('WebSocket connection established.');
      setConnectionStatus('connected');
      retryCount.current = 0;
      if (!initialGreetingSentRef.current) {
        sendWebSocketMessage({
          prompt: GREETING_PROMPT,
          aiType: AI_MODEL,
          startNewChat: true
        });
        initialGreetingSentRef.current = true;
      }
    };

    ws.current.onclose = (event) => {
      console.log('WebSocket connection closed:', event);

      if (retryCount.current < MAX_RETRIES) {
        retryCount.current += 1;
        console.log(`Retrying connection (${retryCount.current}/${MAX_RETRIES})...`);
        setConnectionStatus('reconnecting');
        reconnectTimeoutRef.current = setTimeout(connectWebSocket, RETRY_DELAY);
      } else {
        setConnectionStatus('failed');
        showToast("Error", "Failed to establish WebSocket connection. Please try again later.", "error");
      }
    };

    ws.current.onmessage = (event) => {
      try {
        const messagePart = JSON.parse(event.data);
        processMessage(messagePart);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };
  }, [processMessage, showToast, sendWebSocketMessage]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [connectWebSocket]);

  const sendMessage = useCallback((message, aiType, startNewChat = false) => {
    if (connectionStatus !== 'connected') {
      showToast("Error", "Not connected to the server. Please try again later.", "error");
      return;
    }

    if (remainingMessages > 0) {
      sendWebSocketMessage({ prompt: message, aiType, startNewChat });
    } else {
      showToast("Error", "Rate limit exceeded. Please try again later.", "error");
    }
  }, [connectionStatus, remainingMessages, showToast, sendWebSocketMessage]);

  return {
    sendMessage,
    sendGreeting: useCallback(() => {
      if (connectionStatus === 'connected' && !initialGreetingSentRef.current) {
        sendWebSocketMessage({
          prompt: GREETING_PROMPT,
          aiType: AI_MODEL,
          startNewChat: true
        });
        initialGreetingSentRef.current = true;
      }
    }, [connectionStatus, sendWebSocketMessage]),
    typingMessage,
    isThinking,
    remainingMessages,
    isConnecting: connectionStatus === 'connecting' || connectionStatus === 'reconnecting',
    connectionStatus
  };
};

export default useWebSocket;