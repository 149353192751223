import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const useMessageStyle = (isUserMessage, themeColors) => useMemo(() => ({
    bg: isUserMessage ? 'white' : `linear-gradient(to right, ${themeColors.brand.pink}, ${themeColors.brand.purple}, ${themeColors.brand.blue})`,
    color: isUserMessage ? 'black' : 'white',
    p: 2,
    borderRadius: 'md',
    maxWidth: isUserMessage ? '70%' : '85%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    wordBreak: 'break-word',
    transition: 'all 0.3s ease',
}), [isUserMessage, themeColors.brand.pink, themeColors.brand.purple, themeColors.brand.blue]);

const markdownComponents = {
    ol: ({ children }) => (
        <ol style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>{children}</ol>
    ),
    ul: ({ children }) => (
        <ul style={{ paddingLeft: '20px', listStylePosition: 'inside' }}>{children}</ul>
    ),
    li: ({ children, ordered }) => (
        <li style={{
            marginBottom: '4px',
            marginLeft: ordered ? '0' : '-20px',
            paddingLeft: ordered ? '0' : '20px'
        }}>
            {children}
        </li>
    ),
    p: ({ children }) => (
        <p style={{ marginBottom: '10px' }}>{children}</p>
    ),
};

const Message = ({ message, themeColors, isUserMessage }) => {
    const messageStyle = useMessageStyle(isUserMessage, themeColors);

    const displayText = useMemo(() =>
            (message.text || '').replace(/(?<!\n- .*)\n(?!- )/g, '\n\n'),
        [message.text]
    );

    return (
        <Flex
            align="center"
            justify={isUserMessage ? 'flex-end' : 'flex-start'}
            width="100%"
            mb={2}
        >
            <Box {...messageStyle}>
                <ReactMarkdown components={markdownComponents}>
                    {displayText}
                </ReactMarkdown>
            </Box>
        </Flex>
    );
};

Message.propTypes = {
    message: PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
    }).isRequired,
    themeColors: PropTypes.shape({
        brand: PropTypes.shape({
            pink: PropTypes.string.isRequired,
            purple: PropTypes.string.isRequired,
            blue: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    isUserMessage: PropTypes.bool.isRequired,
};

export default React.memo(Message, (prevProps, nextProps) =>
    prevProps.message.id === nextProps.message.id &&
    prevProps.message.text === nextProps.message.text &&
    prevProps.isUserMessage === nextProps.isUserMessage
);