import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { ChakraProvider, Flex, Box, Text, extendTheme, useToast, Spinner, Center, useDisclosure } from '@chakra-ui/react';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import ReportModal from './ReportModal';
import useWebSocket from '../Websocket/useWebSocket';
import { AI_MODEL } from "../Utils/AiUtils";
import { reportBug } from '../APIs/backboneApi';
import { THEME_COLORS, FACTS, SENDER_USER, FACT_ROTATION_INTERVAL, TOAST_DURATION } from '../Constants.mjs';

const ChatComponent = () => {
    
    const [messages, setMessages] = useState([]);
    const [currentFactIndex, setCurrentFactIndex] = useState(0);
    const [startNewChatFlag, setStartNewChatFlag] = useState(false);
    const { sendMessage, sendGreeting, typingMessage, isThinking, remainingMessages, isConnecting, connectionStatus } = useWebSocket(setMessages);
    const toast = useToast();
    const { isOpen: isReportModalOpen, onOpen: openReportModal, onClose: closeReportModal } = useDisclosure();

    const customTheme = useMemo(() => extendTheme({
        styles: {
            global: {
                body: {
                    bg: THEME_COLORS.background,
                    color: THEME_COLORS.text,
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                },
            },
        },
        colors: {
            brand: THEME_COLORS.brand,
        },
    }), []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentFactIndex((prevIndex) => (prevIndex + 1) % FACTS.length);
        }, FACT_ROTATION_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (connectionStatus === 'connected') {
            sendGreeting();
        }
    }, [connectionStatus, sendGreeting]);

    const handleSendMessage = useCallback((message) => {
        if (message.trim() === '') return;
        setMessages(prevMessages => [
            ...prevMessages,
            {
                sender: SENDER_USER,
                text: message,
                id: `user-${Date.now()}`
            }
        ]);
        sendMessage(message, AI_MODEL, startNewChatFlag);
        setStartNewChatFlag(false);
    }, [sendMessage, startNewChatFlag]);

    const handleNewChat = useCallback(() => {
        setMessages([]);
        setStartNewChatFlag(true);
        sendGreeting();
    }, [sendGreeting]);

    const handleReportSubmit = useCallback(async (title, description) => {
        try {
            await reportBug(title, description, { messages });
            toast({
                title: "Report Submitted",
                description: "Thank you for your feedback.",
                status: "success",
                duration: TOAST_DURATION,
                isClosable: true,
            });
            closeReportModal();
        } catch (error) {
            console.error('Error submitting report:', error);
            toast({
                title: "Error",
                description: "Failed to submit report. Please try again.",
                status: "error",
                duration: TOAST_DURATION,
                isClosable: true,
            });
        }
    }, [messages, toast, closeReportModal]);

    if (isConnecting) {
        return (
            <Center height="100vh" bg={THEME_COLORS.background}>
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color={THEME_COLORS.brand.blue}
                    size="xl"
                />
            </Center>
        );
    }

    return (
        <ChakraProvider theme={customTheme}>
            <Flex
                direction="column"
                height="100vh"
                bg={THEME_COLORS.background}
                overflow="hidden"
            >
                <Box flexShrink={0}>
                    <ChatHeader
                        remainingMessages={remainingMessages}
                        onNewChat={handleNewChat}
                        messageCount={messages.length}
                    />
                </Box>
                <Flex direction="column" flex={1} overflow="hidden">
                    <Box flex={1} overflowY="auto" px={4} pb={2}>
                        <MessageList
                            messages={messages}
                            typingMessage={typingMessage}
                            isThinking={isThinking}
                            themeColors={THEME_COLORS}
                            isRateLimited={remainingMessages === 0}
                        />
                    </Box>
                    {isThinking && (
                        <Box
                            textAlign="center"
                            color="gray.400"
                            py={2}
                            px={4}
                            bg={THEME_COLORS.background}
                        >
                            <Text>{FACTS[currentFactIndex]}</Text>
                        </Box>
                    )}
                </Flex>
                <Box flexShrink={0}>
                    <MessageInput
                        onSendMessage={handleSendMessage}
                        isRateLimited={remainingMessages === 0}
                        isConnecting={isConnecting}
                        onReportClick={openReportModal}
                        isReportModalOpen={isReportModalOpen}
                    />
                </Box>
            </Flex>
            <ReportModal
                isOpen={isReportModalOpen}
                onClose={closeReportModal}
                onSubmit={handleReportSubmit}
            />
        </ChakraProvider>
    );
};

export default ChatComponent;