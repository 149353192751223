import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Textarea, Button, Box, IconButton, Tooltip } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

const PLACEHOLDER_TEXT = {
    connecting: "Connecting...",
    default: "Type a message..."
};

const useAutoResizeTextarea = (value) => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (ref.current) {
            ref.current.style.height = 'auto';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }, [value]);

    return ref;
};

const MessageInput = ({ onSendMessage, isRateLimited, isConnecting, onReportClick, isReportModalOpen }) => {
    const [input, setInput] = useState('');
    const [showHoverTooltip, setShowHoverTooltip] = useState(false);
    const textareaRef = useAutoResizeTextarea(input);

    const isButtonDisabled = useMemo(() =>
            input.trim() === '' || isRateLimited || isConnecting,
        [input, isRateLimited, isConnecting]
    );

    const handleSend = useCallback(() => {
        if (!isButtonDisabled) {
            onSendMessage(input);
            setInput('');
        }
    }, [isButtonDisabled, onSendMessage, input]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    }, [handleSend]);

    const handleInputChange = useCallback((e) => {
        setInput(e.target.value);
    }, []);

    const handleReportMouseEnter = useCallback(() => setShowHoverTooltip(true), []);
    const handleReportMouseLeave = useCallback(() => setShowHoverTooltip(false), []);

    return (
        <Box width="100%" pb={{ base: 4, md: 0 }} bg="#060a0d">
            <Flex width="100%" p={6} alignItems="flex-end">
                <Textarea
                    ref={textareaRef}
                    placeholder={isConnecting ? PLACEHOLDER_TEXT.connecting : PLACEHOLDER_TEXT.default}
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    mr={2}
                    borderColor="brand.purple"
                    bg="white"
                    color="black"
                    flex="1"
                    isDisabled={isRateLimited}
                    resize="none"
                    minHeight="60px"
                    maxHeight="200px"
                    overflow="auto"
                    rows={1}
                    lineHeight="1.5"
                    py={2}
                    px={3}
                />
                <Button
                    bgGradient="linear(to-r, brand.pink, brand.blue)"
                    onClick={handleSend}
                    isDisabled={isButtonDisabled}
                    _hover={{ opacity: isButtonDisabled ? 1 : 0.8 }}
                    _disabled={{
                        bgGradient: "linear(to-r, gray.400, gray.500)",
                        cursor: "not-allowed",
                        opacity: 0.6
                    }}
                    mr={2}
                    alignSelf="flex-end"
                >
                    Send
                </Button>
                <Tooltip
                    label="I am still new and learning! If I did something wrong, please press this to report it so I can get the right data to improve!"
                    isOpen={showHoverTooltip && !isReportModalOpen}
                    hasArrow
                    placement="top"
                >
                    <IconButton
                        icon={<WarningIcon />}
                        onClick={onReportClick}
                        aria-label="Report issue"
                        size="sm"
                        variant="outline"
                        colorScheme="yellow"
                        onMouseEnter={handleReportMouseEnter}
                        onMouseLeave={handleReportMouseLeave}
                        alignSelf="flex-end"
                    />
                </Tooltip>
            </Flex>
        </Box>
    );
};

MessageInput.propTypes = {
    onSendMessage: PropTypes.func.isRequired,
    isRateLimited: PropTypes.bool.isRequired,
    isConnecting: PropTypes.bool.isRequired,
    onReportClick: PropTypes.func.isRequired,
    isReportModalOpen: PropTypes.bool.isRequired
};

export default React.memo(MessageInput);