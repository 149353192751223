import React, { useLayoutEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { VStack, Spinner, Text, Box } from '@chakra-ui/react';
import Message from './Message';

const MessageList = ({ messages, typingMessage, isThinking, themeColors, isRateLimited }) => {
    const containerRef = useRef(null);
    const bottomRef = useRef(null);

    const allMessages = useMemo(() =>
            [...messages, ...(typingMessage ? [typingMessage] : [])],
        [messages, typingMessage]
    );

    const scrollToBottom = useCallback(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useLayoutEffect(() => {
        scrollToBottom();
    }, [allMessages, isThinking, scrollToBottom]);

    const renderMessage = useCallback((msg) => (
        <Message
            key={msg.id}
            message={msg}
            themeColors={themeColors}
            isUserMessage={msg.sender === 'user'}
        />
    ), [themeColors]);

    const renderThinkingSpinner = useCallback(() => (
        <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={themeColors.brand.blue}
            size="xl"
            alignSelf="left"
            aria-label="Thinking..."
        />
    ), [themeColors.brand.blue]);

    const renderRateLimitMessage = useCallback(() => (
        <Box
            p={3}
            bg="red.500"
            color="white"
            borderRadius="md"
            width="100%"
            role="alert"
        >
            <Text>Rate limit reached. Please try again later.</Text>
        </Box>
    ), []);

    return (
        <VStack
            ref={containerRef}
            spacing={3}
            align="stretch"
            width="100%"
            flex={1}
            overflowY="auto"
            px={4}
            py={2}
            bg={themeColors.background}
            role="log"
            aria-live="polite"
            aria-label="Message list"
        >
            {allMessages.map(renderMessage)}
            {isThinking && !typingMessage && renderThinkingSpinner()}
            {isRateLimited && renderRateLimitMessage()}
            <div ref={bottomRef} aria-hidden="true" />
        </VStack>
    );
};

MessageList.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        sender: PropTypes.string.isRequired,
    })).isRequired,
    typingMessage: PropTypes.shape({
        id: PropTypes.string.isRequired,
        sender: PropTypes.string.isRequired,
    }),
    isThinking: PropTypes.bool.isRequired,
    themeColors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        brand: PropTypes.shape({
            blue: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    isRateLimited: PropTypes.bool.isRequired,
};

export default React.memo(MessageList);